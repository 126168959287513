import { graphql, useStaticQuery } from "gatsby";

export const useProjectImage = (image_names) => {
  if(typeof image_names !== 'object') {
    image_names = [image_names];
  }
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "projects"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)
  return data.allFile.edges.filter(e => image_names.includes(e.node.name));
}