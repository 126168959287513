import React, {useState} from 'react';
import { IconBackCircle, IconForwardCircle } from '../../utils/LegacyIcons';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core'
import {Link} from 'gatsby';
import 'swiper/css';
import {hcProjects} from '../../utils/hardcodedProjects';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useProjectImage } from '../../hooks/use-project-image';

SwiperCore.use([Navigation])

const HardcodedProjectImage = ({imageName, description}) => {
  const projectImage = useProjectImage(imageName)[0].node;
  return (
    <GatsbyImage image={getImage(projectImage)}
      alt={description} />
  )
}

const Carousel = ({projects, project = null, children, title, description}) => {
  const [slidesPerView, setSlidesPerView] = useState(3);
  const onBreakpoint = (swiper, bp) => {
    setSlidesPerView(bp.slidesPerView);
  }
  const sortList = ['Tanzee', 'Vush Stimulation', 'Bach Eyewear'];
  const projectList = Object.entries([...projects, ...hcProjects]).sort((a,b) => sortList.includes(a[1].name) ? -1 : 1);
  const sortedProjects = [];
  for(let [, project] of projectList) {
    sortedProjects.push(project);
  }

  return (
    <section className="bg-white py-24">
      <div className="xl:container mx-auto px-6">
        <div className="text-center mb-12">
          {title && <h3 className="text-3xl md:text-4xl font-black">{title}</h3>}
          {description && <p className="text-xl mt-6">{description}</p>}
        </div>
      </div>
      <div className="relative">
        <div className="swipe-button-prev absolute top-[50%] left-[1rem] lg:left-[2rem] z-10 cursor-pointer flex items-center justify-center">
          <IconBackCircle className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 transition-all hover:scale-110" />
        </div>
        <Swiper simulateTouch={false}
          slidesPerView={3}
          spaceBetween={30}
          loop={true}
          navigation={{ nextEl: '.swipe-button-next', prevEl: '.swipe-button-prev' }}
          speed={200}
          className="!my-12 !mx-16 lg:!mx-24 lg:!my-12 z-[-1]"
          onBreakpoint={onBreakpoint}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
              1600: {
                slidesPerView: 4,
                spaceBetween: 60,
              },
            }}> 
            {sortedProjects.map(({ name, description, src, imageName, to }) =>
              to === project ? null : (
                <SwiperSlide key={`${name}${slidesPerView}`}>
                  <Link to={`/projects/${to}`}
                    className="mb-8">
                    <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-2.5">
                      {src && <img src={src}
                        className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                        alt={description} />}
                      {imageName &&
                        <HardcodedProjectImage imageName={imageName}
                          description={description} />
                      }
                    </div>
                    <p className="font-black text-black text-xl lg:text-2xl mb-4">{name}</p>
                    <p className="text-slate-500">{description}</p>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="swipe-button-next absolute top-[50%] right-[1rem] lg:right-[2rem] z-10 cursor-pointer flex items-center justify-center">
            <IconForwardCircle className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 transition-all hover:scale-110" />
          </div>
      </div>
      {children}
    </section>
  )
}

export default Carousel;