export const hcProjects = [
  {
    name: 'Vush Stimulation',
    description: "Vush Stimulation is a rapidly growing Australian eCommerce brand that started up in 2018.",
    to: 'vush',
    imageName: 'vush-thumbnail-2175w'
  },
  {
    name: 'Bach Eyewear',
    description: 'Bach Eyewear set out to change the way the trade industry sees eyewear.',
    to: 'bach-eyewear',
    imageName: 'bach-eyewear-thumbnail-2175w'
  },
  {
    name: 'Tanzee',
    description: 'Tanzee is a scaling eCommerce brand that is taking the world by storm one tanning routine product at a time.',
    to: 'tanzee',
    imageName: 'tanzee-thumbnail-2175w'
  },
  {
    name: '35mm Co.',
    description: 'Madison came to Sourci in 2020 with the vision of creating her own personal brand of 35mm reusable film cameras.',
    to: '35mm-co',
    imageName: '35mm-co-thumbnail-2175w'
  },
  {
    name: 'CMC Gold',
    description: 'Launched in 2017, Chloe and Maurice started the business, CMC Gold around party supplies.',
    to: 'cmc-gold',
    imageName: 'cmc-gold-feeding-set-2175w'
  },
  {
    name: 'Alexander Street Furniture',
    description: 'Sourci is proud to support Alexander Street Furniture by bringing amazing pieces of contemporary & classic French furniture to life exclusively on their behalf.',
    to: 'alexander-street-furniture',
    imageName: 'alexander-street-furniture-thumbnail-2175w'
  },
  {
    name: 'Noonie',
    description: 'Entrepreneurs Alana Nolan and Winston Su ‘birthed’ Noonie Australia to improve the maternity experience for women.',
    to: 'noonie',
    imageName: 'noonie-1-2175w'
  },
  {
    name: 'Ronnie & Co.',
    description: "More good days, less bad days with Ronnie & Co's range of gratitude journals.",
    to: 'ronnie-co',
    imageName: 'ronnie-co-thumbnail-2175w'
  },
  {
    name: 'Mint Eco Shop',
    description: "Brenda Tootell, founder of Mint Eco discovered Sourci online and learned how we manage the full end-to-end supply chain, mentions, ‘they did an outstanding job… a huge help in getting the brand launched’.",
    to: 'mint-eco-shop',
    imageName: 'bento-2_720x-2175w'
  },
  {
    name: 'Easy Sleep',
    description: 'Sourci is proud to support Easy Sleep Australia by helping them launch their very first private branded range of products.',
    to: 'easy-sleep',
    imageName: 'easy-sleep-thumbnail-2175w'
  },
  {
    name: 'No Fight Tights',
    description: 'A revolution for tights! We helped Corinna navigate a complex product development process with multiple prototypes and exceptional problem-solving to ensure the tights comfortability, durability and quality in this unique proprietary product.',
    to: 'no-fight-tights',
    imageName: 'no-fight-tights-banner-2175w'
  },
  {
    name: 'SafelySecured',
    description: 'SafelySecured came up with a brilliant concept - and partnered with our ongoing development team, this has led to the creation of the worlds first UN-STEALABLE dog leash!',
    to: 'safely-secured',
    imageName: 'safely-secured-thumbnail-2175w'
  },
  {
    name: 'Tiny Treehouses',
    description: 'We adore working with Tiny Treehouses, who have come up with a stupendous idea of making DIY model treehouse kits for your pot plants.',
    to: 'tiny-treehouses',
    imageName: 'tiny-treehouses-1-2175w'
  },
  {
    name: 'PPE (Personal Protective Equipment)',
    description: 'Through some of the most difficult international trade conditions in history, Sourci was able to provide transparency and complete supply chain control for corporates and governments alike.',
    to: 'ppe',
    imageName: 'ppe-thumbnail-2175w'
  }
];